@font-face {
    font-family: 'g-plugin-toc';
    src: url('../fonts/g-plugin-toc.eot?7fj1v8');
    src: url('../fonts/g-plugin-toc.eot?7fj1v8#iefix') format('embedded-opentype'),
        url('../fonts/g-plugin-toc.ttf?7fj1v8') format('truetype'),
        url('../fonts/g-plugin-toc.woff?7fj1v8') format('woff'),
        url('../fonts/g-plugin-toc.svg?7fj1v8#g-plugin-toc') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="g-plugin-toc-"],
[class*=" g-plugin-toc-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'g-plugin-toc' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.g-plugin-toc-capas2:before {
    content: "\e9000";
}

.g-plugin-toc-check2:before {
    content: "\e9001";
}

.g-plugin-toc-check3:before {
    content: "\e9002";
}