.m-panel.m-plugin-toc.collapsed {
    height: 40px;
}

.m-areas div.m-plugin-toc.m-panel>button.m-panel-btn.g-plugin-toc-capas2 {
    font-family: g-plugin-toc !important;
}

.m-areas>div.m-area>div.m-plugin-toc.m-panel.opened>button.m-panel-btn {
    background-color: white !important;
    color: #71a7d3 !important;
    float: none;
    left: -40px;
    position: absolute;
    z-index: 999;
    transition: none;
}

.m-areas>div.m-area.m-left>div.m-plugin-toc.m-panel.opened>button.m-panel-btn {
    background-color: white !important;
    color: #71a7d3 !important;
    float: none;
    left: 350px;
    position: absolute;
    z-index: 999;
    transition: none;
}

.m-areas>div.m-area>div.m-plugin-toc.m-panel.opened>.m-panel-controls .m-toc-panel {
    width: 350px;
}

.m-areas div.m-plugin-toc.m-panel.opened .m-toc-panel ul.m-toc-content {
    padding-left: 0;
    list-style: none;
    margin: 0;

    max-height: 25vh;
    overflow-y: auto;
}

.m-areas div.m-plugin-toc.m-panel.opened div.title {
    background-color: #71a7d3 !important;
    color: white !important;
    line-height: normal;
    margin: 0px;
    font-size: 15px;
    /* color: #404040; */
    font-family: 'Muli', sans-serif !important;
    line-height: 40px;
    text-align: center;
    height: 39px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    font-size: 15px;
}

.m-control.m-container.m-toc.m-toc-container .m-toc-panel .m-toc-content li div.layer span.m-check {
    cursor: pointer;
    color: #6abe47;
    margin: 0px;
    margin-right: 1%;
}

.m-control.m-container.m-toc .m-toc-content div.layer {
    font-family: sans-serif;
    font-size: 0.8em;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.4em;
    line-height: 40px;
    font-size: 15px;
    text-align: left;
    margin: 10px 5px;
    padding: 2px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fff;
}

.m-control.m-container.m-toc .m-toc-content {
    cursor: pointer;
}

.m-toc-container .m-toc-panel {
    margin: 0;
    top: 0px;
    right: 0px;
    height: 100%;
    padding-bottom: 1px;
    text-align: left;
    background-color: #e9e8e8;
}

@media only screen and (max-width: 768px) {
    .m-areas>div.m-area>div.m-plugin-toc.m-panel.opened>.m-panel-controls .m-toc-panel {
        width: 350px;
    }
}